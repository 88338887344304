import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-medium-image-zoom/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import CountryFlag from "react-country-flag";
import LinkIcon from "@mui/icons-material/Link";
import {
  fetchPropertyData,
  setLanguage,
  translateStaticContent,
} from "../../../redux/Slices/GuestPage/guestSlice";
import {
  selectIsLoadingData,
  selectIsLoadingTranslation,
} from "../../../redux/Slices/GuestPage/guestSlice";

const UserPropertyPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Explicitly type dispatch
  const [expandedCategories, setExpandedCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedGuide, setSelectedGuide] = useState<any | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [copied, setCopied] = useState(false);

  // URL parameters to get property ID and mode
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const mode = searchParams.get("mode") || "property";

  const isReservation = mode === "reservation";
  const isTemplate = mode === "template";
  // Separate loading state selectors
  const isLoadingData = useSelector(selectIsLoadingData);
  const isLoadingTranslation = useSelector(selectIsLoadingTranslation);

  const shareableLink = `${window.location.origin}${
    window.location.pathname
  }?id=${searchParams.get("id")}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Step 3: Dialog and Category Expand Handlers
  const handleToggleCategory = (index: number) => {
    setExpandedCategories((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleGuideClick = (guide: any) => {
    setSelectedGuide(guide);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [isShareDialogOpen, setShareDialogOpen] = useState(false);

  const openShareDialog = () => setShareDialogOpen(true);
  const closeShareDialog = () => setShareDialogOpen(false);

  // Accessing data and loading state from Redux
  const {
    property,
    staticText,
    selectedLanguage,
    fetchError,
    translationError,
  } = useSelector((state: RootState) => state.property);

  useLayoutEffect(() => {
    if (isReservation && id) {
      (async () => {
        const action = await dispatch(
          fetchPropertyData({ id, isReservation, lang: undefined })
        );

        // Check if action was fulfilled
        if (fetchPropertyData.fulfilled.match(action)) {
          const fetchedLanguage = action.payload.language || "en";
          dispatch(setLanguage(fetchedLanguage));

          if (fetchedLanguage !== "en") {
            dispatch(translateStaticContent(fetchedLanguage));
          }
        } else {
          console.error("Error fetching property data");
        }
      })();
    }
  }, [dispatch, id, isReservation]);

  // Step 1: Initial Fetch with Persisted Language Setting
  useEffect(() => {
    if (id) {
      dispatch(
        fetchPropertyData({ id, isReservation, lang: selectedLanguage })
      );
      if (selectedLanguage !== "en") {
        dispatch(translateStaticContent(selectedLanguage));
      }
    }
  }, [dispatch, id, isReservation, selectedLanguage]);
  // Step 2: Language Change Handler
  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value;
    dispatch(setLanguage(newLanguage)); // Update the language state
    dispatch(fetchPropertyData({ id, isReservation, lang: newLanguage })); // Fetch property data

    // Always dispatch translateStaticContent, even if the language is "en"
    dispatch(translateStaticContent(newLanguage));
  };

  // List of language options
  const languageOptions = [
    { code: "en", label: "English" },
    { code: "fr", label: "French" },
    { code: "es", label: "Spanish" },
    { code: "de", label: "German" },
    { code: "ar", label: "Arabic" },
    { code: "zh", label: "Chinese" },
    { code: "it", label: "Italian" },
    { code: "pt", label: "Portuguese" },
  ];

  const getCountryCode = (languageCode: string) => {
    const fallbackCountryCode: { [key: string]: string } = {
      en: "US",
      fr: "FR",
      es: "ES",
      de: "DE",
      ar: "SA",
      zh: "CN",
      it: "IT",
      pt: "BR",
    };
    return (
      fallbackCountryCode[languageCode as keyof typeof fallbackCountryCode] ||
      languageCode.toUpperCase()
    );
  };

  if (isLoadingData || isLoadingTranslation)
    return <div className="text-center mt-10">Loading...</div>;
  if (fetchError)
    return <div className="text-center mt-10">{staticText.error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Error Messages */}
      {fetchError && (
        <div className="text-center mb-6">
          <p className="text-red-600 font-semibold">{fetchError}</p>
        </div>
      )}
      {translationError && (
        <div className="text-center mb-6">
          <p className="text-red-600 font-semibold">{translationError}</p>
        </div>
      )}

      {isTemplate && (
        <div className="flex items-center space-x-2">
          {/* Copy Link Button */}
          <Tooltip title={copied ? "Link Copied!" : "Copy Link"}>
            <IconButton onClick={copyToClipboard} sx={{ color: "black" }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}

      {/* Language Switcher */}
      <div className="text-right mb-4">
        <label htmlFor="language-switcher" className="mr-2">
          {staticText.languageSwitcherLabel || "Select Display Language:"}
        </label>
        <select
          id="language-switcher"
          value={selectedLanguage}
          onChange={handleLanguageChange}
          className="border rounded-lg px-2 py-1"
        >
          {languageOptions.map((option) => (
            <option key={option.code} value={option.code}>
              <CountryFlag
                countryCode={getCountryCode(option.code)}
                style={{ width: "20px", marginRight: "5px" }}
              />
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {/* Property Header */}
      <div className="text-center">
        <h1 className="text-3xl font-bold">{property?.name}</h1>
      </div>

      {/* Property Main Image */}
      {property?.main_image && (
        <div className="mt-8 flex justify-center">
          <img
            src={property.main_image}
            alt={property.name}
            className="rounded-lg shadow-md w-full max-w-3xl"
          />
        </div>
      )}

      {/* Property Address */}
      <div className="text-center mt-4">
        <p className="text-gray-600">
          {property?.address && `${property.address}`}
          {property?.zip && `, ${property.zip}`}
          {property?.city && ` ${property.city}`}
          {property?.state && `, ${property.state}`}
        </p>
        {/* Google Maps Link */}
        {property?.latitude && property?.longitude && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${property.latitude},${property.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline mt-2 block"
          >
            {staticText.viewOnGoogleMaps}
          </a>
        )}
      </div>

      {/* Guides Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold">{staticText.guides}</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
          {property?.featured_guides.map((guide, index) => (
            <div
              key={index}
              className="bg-gray-100 p-4 rounded-lg shadow cursor-pointer"
              onClick={() => handleGuideClick(guide)}
            >
              {guide.main_image ? (
                <img
                  src={guide.main_image}
                  alt={guide.name}
                  className="rounded-lg shadow-md w-full h-24 object-cover mb-2"
                />
              ) : (
                guide.medias &&
                guide.medias.length > 0 && (
                  <img
                    src={guide.medias[0]?.file}
                    alt={guide.name}
                    className="rounded-lg shadow-md w-full h-24 object-cover mb-2"
                  />
                )
              )}
              <h3 className="text-lg font-bold">{guide.name}</h3>
            </div>
          ))}
        </div>
      </div>

      {/* Stay Details with FAQ-style Amenities */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold">
          {staticText.faqTitle ?? "FAQ"}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {/* Render FAQ entries from API */}
          {property?.faqs.map((faq) => (
            <div key={faq.id} className="bg-white p-4 rounded-lg shadow">
              <strong>{faq.question}</strong>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Categories and Guides Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold">{staticText.guides}</h2>
        {property?.categories.map((category, categoryIndex) => (
          <div
            key={categoryIndex}
            className="mt-6 bg-white p-4 rounded-lg shadow"
          >
            <h3
              className="text-xl font-bold cursor-pointer p-2 flex justify-between items-center"
              onClick={() => handleToggleCategory(categoryIndex)}
            >
              {/* Category Image with Rounded Corners */}
              <div className="flex items-center">
                {category.image && (
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-10 h-10 rounded-lg mr-4 object-cover"
                  />
                )}
                <span>{category.name}</span>
              </div>

              <span className="ml-2">
                {expandedCategories[categoryIndex] ? "−" : "+"}
              </span>
            </h3>

            {/* Guides inside the category */}
            {expandedCategories[categoryIndex] && (
              <div className="grid grid-cols-1 gap-4 mt-4">
                {category.guides.map((guide, guideIndex) => (
                  <div
                    key={guideIndex}
                    className="bg-gray-100 p-4 rounded-lg shadow cursor-pointer flex items-center"
                    onClick={() => handleGuideClick(guide)}
                  >
                    {guide.main_image && (
                      <img
                        src={guide.main_image}
                        alt={guide.name}
                        className="w-16 h-16 rounded-lg object-cover mr-4"
                      />
                    )}
                    <div>
                      <h4 className="text-lg font-semibold">{guide.name}</h4>
                      {/* <div className="mt-2">{parse(guide.description)}</div> */}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Dialog for Guide Details */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        {selectedGuide && (
          <>
            <DialogTitle>
              {selectedGuide.name}
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <div className="w-full">
                {/* Main Image */}
                {selectedGuide.main_image ? (
                  <img
                    src={selectedGuide.main_image}
                    alt={selectedGuide.name || "Guide"}
                    className="w-auto h-48 mb-4 rounded-t-lg mx-auto"
                  />
                ) : (
                  <div className="h-48 bg-gray-200 mb-4 rounded-t-lg flex items-center justify-center">
                    <span className="text-gray-500">
                      {staticText.noMediaImages}
                    </span>
                  </div>
                )}

                {/* Guide Title */}
                {selectedGuide.name ? (
                  <h2 className="text-xl font-semibold mb-4">
                    {selectedGuide.name}
                  </h2>
                ) : (
                  <h2 className="text-xl font-semibold mb-4 text-gray-500">
                    {staticText.untitledGuide}
                  </h2>
                )}

                {/* Guide Description */}
                {selectedGuide.description ? (
                  <div className="text-gray-700 mb-6">
                    {parse(selectedGuide.description)}
                  </div>
                ) : (
                  <div className="text-gray-500 mb-6">
                    {staticText.noDescription}
                  </div>
                )}

                {/* Media Images */}
                <hr className="border-t border-gray-300 my-4" />
                {selectedGuide.medias && selectedGuide.medias.length > 0 ? (
                  <div className="grid grid-cols-1 gap-4">
                    {selectedGuide.medias.map((media: any, index: number) => (
                      <div key={index}>
                        <img
                          src={media.file}
                          alt=""
                          className="w-full h-auto mb-4 rounded-lg"
                        />
                        {index < selectedGuide.medias.length - 1 && (
                          <hr className="border-t border-gray-300 my-4" />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-gray-500 mb-4">
                    {staticText.noMediaImages}
                  </div>
                )}
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>

      {/* Google Maps and Contact Section */}
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Google Maps Embed */}
        <div className="rounded-lg overflow-hidden shadow-lg w-full h-64 md:h-auto">
          {property?.latitude && property?.longitude ? (
            <iframe
              src={`https://www.google.com/maps?q=${property.latitude},${property.longitude}&z=15&output=embed`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              title="Google Maps"
            ></iframe>
          ) : (
            <p>{staticText.locationNotAvailable}</p>
          )}
        </div>

        {/* Contact Details */}
        <div className="flex flex-col justify-center items-start space-y-4 sm:space-y-6 bg-gray-50 p-4 sm:p-6 rounded-lg shadow-lg">
          <h2 className="text-xl sm:text-2xl font-semibold">
            {staticText.contactUs}
          </h2>
          <div className="text-base sm:text-lg">
            <p>
              <strong>{staticText.phoneLabel}:</strong>{" "}
              {property?.contact?.phone_number ?? staticText.notSpecified}
            </p>
            <p>
              <strong>{staticText.emailLabel}:</strong>{" "}
              {property?.contact?.email ?? staticText.notSpecified}
            </p>
          </div>

          {/* Contact Buttons */}
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-3 sm:space-y-0">
            {property?.contact?.phone_number && (
              <a
                href={`https://wa.me/${property.contact.phone_number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center space-x-2 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition text-sm sm:text-base"
              >
                <i className="fab fa-whatsapp"></i>
                <span>{staticText.whatsapp}</span>
              </a>
            )}
            {property?.contact?.phone_number && (
              <a
                href={`tel:${property.contact.phone_number}`}
                className="flex items-center justify-center space-x-2 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition text-sm sm:text-base"
              >
                <i className="fas fa-phone-alt"></i>
                <span>{staticText.call}</span>
              </a>
            )}
            {property?.contact?.email && (
              <a
                href={`mailto:${property.contact.email}`}
                className="flex items-center justify-center space-x-2 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition text-sm sm:text-base"
              >
                <i className="fas fa-envelope"></i>
                <span>{staticText.email}</span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPropertyPage;
