import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { translateStaticText } from "../../../utils/translation/aws.translate";
import { RootState } from "../../../app/store";
import propertiesApi from "../../../features/public/guestPage/guest.services";
import {
  defaultStaticText,
  Property,
  StaticText,
} from "../../../features/public/guestPage/guest.types";

// Helper to persist language in local storage
const persistLanguage = (language: string) =>
  localStorage.setItem("selectedLanguage", language);
const loadPersistedLanguage = () =>
  localStorage.getItem("selectedLanguage") || "en";

// Thunks for data fetching and translation
export const fetchPropertyData = createAsyncThunk<
  Property,
  { id: string; isReservation?: boolean; lang?: string },
  { state: RootState }
>(
  "property/fetchData",
  async (
    { id, isReservation, lang },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState();
    const selectedLanguage = lang || state.property.selectedLanguage || "en";
    try {
      const propertyData = await dispatch(
        propertiesApi.endpoints.getExtendedPropertyById.initiate({
          id,
          isReservation,
          lang: selectedLanguage,
        })
      ).unwrap();
      return propertyData;
    } catch (error) {
      return rejectWithValue("Failed to load data.");
    }
  }
);

export const translateStaticContent = createAsyncThunk<
  StaticText,
  string,
  { state: RootState }
>("property/translateStaticText", async (lang, { rejectWithValue }) => {
  try {
    return lang !== "en" ? await translateStaticText(lang) : defaultStaticText;
  } catch (error) {
    return rejectWithValue("Failed to translate content.");
  }
});

export const updateLanguage = createAsyncThunk<
  void,
  { lang: string; id: string; isReservation?: boolean },
  { state: RootState }
>(
  "property/updateLanguage",
  async ({ lang, id, isReservation }, { dispatch }) => {
    dispatch(setLanguage(lang));
    await dispatch(fetchPropertyData({ id, isReservation, lang }));
    await dispatch(translateStaticContent(lang));
  }
);

interface PropertyState {
  property: Property | null;
  selectedLanguage: string;
  staticText: StaticText;
  isLoadingData: boolean;
  isLoadingTranslation: boolean;
  fetchError: string | null;
  translationError: string | null;
}

const initialState: PropertyState = {
  property: null,
  selectedLanguage: loadPersistedLanguage(),
  staticText: defaultStaticText,
  isLoadingData: false,
  isLoadingTranslation: false,
  fetchError: null,
  translationError: null,
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
      persistLanguage(action.payload);
    },
    resetError: (state) => {
      state.fetchError = null;
      state.translationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyData.pending, (state) => {
        state.isLoadingData = true;
        state.fetchError = null;
      })
      .addCase(fetchPropertyData.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.property = action.payload;
      })
      .addCase(fetchPropertyData.rejected, (state, action) => {
        state.isLoadingData = false;
        state.fetchError = action.payload as string;
      })
      .addCase(translateStaticContent.pending, (state) => {
        state.isLoadingTranslation = true;
        state.translationError = null;
      })
      .addCase(translateStaticContent.fulfilled, (state, action) => {
        state.isLoadingTranslation = false;
        state.staticText = action.payload;
      })
      .addCase(translateStaticContent.rejected, (state, action) => {
        state.isLoadingTranslation = false;
        state.translationError = action.payload as string;
      });
  },
});

export const { setLanguage, resetError } = propertySlice.actions;
export default propertySlice.reducer;

// Selectors for loading states
export const selectIsLoadingData = (state: RootState) =>
  state.property.isLoadingData;
export const selectIsLoadingTranslation = (state: RootState) =>
  state.property.isLoadingTranslation;
