import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaHome, FaCalendarAlt, FaUserCircle, FaCog } from "react-icons/fa"; // Use only the filled icons
import { useDispatch } from "react-redux";
import { clearToken } from "../../redux/Slices/Authentication/authSlice";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuItem,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material"; // MUI imports
import { AppDispatch } from "../../app/store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm"; // Make sure to create this component

const Navbar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>(); // Use AppDispatch for correct typing
  const navigate = useNavigate();
  const stripePromise = loadStripe("your-publishable-key-here");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    dispatch(clearToken());
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/login", { replace: true });
  };

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => setAnchorEl(null);

  const changeLanguage = async (event: SelectChangeEvent<string>) => {
    const language = event.target.value;
    try {
      await i18n.changeLanguage(language);
      localStorage.setItem("i18nextLng", language);
    } catch (error) {
      console.error("Error changing language:", error);
    } finally {
      window.location.reload();
    }
  };

  return (
    <>
      {/* Navbar for large screens */}
      <nav className="relative bg-gray-100 shadow-lg p-4 w-[100%] mx-auto flex justify-between items-center lg:flex hidden">
        <div className="text-xl font-bold text-black">PORTAS</div>
        <div className="flex space-x-8 mx-auto">
          <NavLink
            to="/properties"
            className={({ isActive }) =>
              isActive ? "text-black font-bold" : "text-black hover:text-black"
            }
          >
            {t("navbar.properties")}
          </NavLink>
          <NavLink
            to="/calendar"
            className={({ isActive }) =>
              isActive ? "text-black font-bold" : "text-black hover:text-black"
            }
          >
            {t("navbar.calendar")}
          </NavLink>
        </div>

        <div className="flex space-x-4 items-center">
          {/* <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "black",
              color: "white",
              fontSize: "0.75rem",
              padding: "4px 8px",
            }}
            onClick={handleClickOpen}
          >
            Upgrade
          </Button> */}
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm" // You can adjust the maxWidth to 'xs', 'sm', 'md', 'lg', or 'xl' as needed
          >
            <DialogTitle>Pay 5 Dollars</DialogTitle>
            <DialogContent>
              <Elements stripe={stripePromise}>
                <CheckoutForm /> {/* Pass the amount in cents */}
              </Elements>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <div className="flex space-x-4 items-center relative">
            {/* <FaCog className="text-2xl cursor-pointer" /> */}
            <IconButton onClick={handleProfileMenuClick}>
              <FaUserCircle className="text-2xl cursor-pointer" />
            </IconButton>
          </div>

          {/* Profile Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {/* <MenuItem
              onClick={() => {
                handleProfileMenuClose();
                navigate("/profile");
              }}
            >
              {t("navbar.profile")}
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleProfileMenuClose();
                handleLogout();
              }}
              style={{ color: "red" }}
            >
              {t("navbar.logout")}
            </MenuItem>
          </Menu>

          {/* <FormControl variant="outlined" size="small">
            <InputLabel>{t("navbar.changeLanguage")}</InputLabel>
            <Select
              native
              value={i18n.language}
              onChange={changeLanguage}
              label={t("navbar.changeLanguage")}
            >
              <option value="en">{t("navbar.english")}</option>
              <option value="fr">{t("navbar.french")}</option>
            </Select>
          </FormControl> */}
        </div>
      </nav>

      {/* Bottom Navbar for mobile */}
      <nav className="lg:hidden fixed bottom-0 left-0 right-0 bg-gray-100 shadow-lg p-2 flex justify-around items-center">
        <NavLink
          to="/properties"
          className={({ isActive }) =>
            isActive ? "text-black font-bold" : "text-black hover:text-black"
          }
        >
          <div className="flex flex-col items-center">
            <FaHome
              className={`text-3xl ${
                window.location.pathname === "/properties"
                  ? "text-black"
                  : "text-gray-400"
              }`}
            />
            <span className="block text-sm">{t("navbar.properties")}</span>
          </div>
        </NavLink>

        <NavLink
          to="/calendar"
          className={({ isActive }) =>
            isActive ? "text-black font-bold" : "text-black hover:text-black"
          }
        >
          <div className="flex flex-col items-center">
            <FaCalendarAlt
              className={`text-3xl ${
                window.location.pathname === "/calendar"
                  ? "text-black"
                  : "text-gray-400"
              }`}
            />
            <span className="block text-sm">{t("navbar.calendar")}</span>
          </div>
        </NavLink>
      </nav>
    </>
  );
};

export default Navbar;
