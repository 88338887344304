import React, { useEffect, useState } from "react";
import propertiesApi, {
  useGetPropertiesQuery,
  useCheckNewPropertiesQuery,
  useGetConnectionsQuery,
  useConnectToAccountMutation,
} from "./properties.service"; // Adjust the path as needed

import PropertiesTemplate from "../../../../components/templates /PropertiesTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../app/store";
import { truncate } from "fs";
import { toast } from "react-toastify";

const Properties: React.FC = () => {
  const [page, setPage] = useState(1);
  const [showHidden, setShowHidden] = useState<boolean>(() => {
    const savedState = localStorage.getItem("showHidden");
    return savedState ? JSON.parse(savedState) : false; // Default to 'false' if no saved state
  });
  const [loading, setLoading] = useState(false); // For button loading state
  const [fetched, setFetched] = useState(false); // To display "Fetched" after success

  const [isConnected, setIsConnected] = useState(false); // Connection state
  const [showSidebar, setShowSidebar] = useState(false); // Sidebar visibility
  const [apiKey, setApiKey] = useState(""); // API key state
  const dispatch = useDispatch<AppDispatch>(); // Use AppDispatch for correct typing

  // Step 1: Check if user has Lodgify connection
  const { data: connectionsData, isLoading: isCheckingConnection } =
    useGetConnectionsQuery();

  // Step 2: Check for new properties
  const { refetch: checkNewProperties } = useCheckNewPropertiesQuery(); // No `skip: true`

  // Step 3: Fetch properties
  const {
    data: propertiesData,
    error,
    isLoading,
    refetch: fetchProperties,
  } = useGetPropertiesQuery({
    page,
    is_hidden: showHidden,
  });

  // Step 4: Connect to account mutation
  const [connectToAccount] = useConnectToAccountMutation();

  useEffect(() => {
    if (connectionsData?.lodgify) {
      setIsConnected(true); // If the user has a connection, update the state
    }
  }, [connectionsData]);

  // Refetch properties when showHidden changes
  useEffect(() => {
    fetchProperties(); // Refetch properties when 'showHidden' state changes
  }, [showHidden, fetchProperties]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setShowHidden(checked); // Update the showHidden state
    setPage(1); // Reset the page to 1
    fetchProperties();
    localStorage.setItem("showHidden", JSON.stringify(checked)); // Persist the user's preference
  };

  // Handle API key input change
  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  // Handle button click for fetching or connecting
  const handleButtonClick = async () => {
    if (isConnected) {
      try {
        setLoading(true); // Start loading when the fetch starts
        const newProperties = await dispatch(
          propertiesApi.endpoints.checkNewProperties.initiate()
        ).unwrap();

        if (newProperties) {
          await fetchProperties(); // Fetch all properties after checking new ones
        }

        // After successful fetch, show "Fetched" for 2 seconds
        setFetched(true);
        setTimeout(() => {
          setFetched(false); // Reset "Fetched" back to normal state after 2 seconds
        }, 2000);
      } catch (error: any) {
        console.error("Error checking or fetching new properties:", error);
      } finally {
        setLoading(false); // Stop loading after fetch is complete (whether successful or not)
      }
    } else {
      setShowSidebar(true); // Show sidebar for API key input if not connected
    }
  };

  const handleConnect = async () => {
    try {
      // Attempt to connect using the API key
      await connectToAccount({ api_key: apiKey }).unwrap();

      // If successful, update the connection state and close the sidebar
      setIsConnected(true);
      setShowSidebar(false); // Automatically close the sidebar upon success

      // Check for new properties after connection
      await dispatch(
        propertiesApi.endpoints.checkNewProperties.initiate()
      ).unwrap();

      // Fetch properties after checking for new ones
      await fetchProperties();
      fetchProperties(); // Refetch properties when 'showHidden' state changes
    } catch (error: any) {
      toast.error(`${error.data.api_key}`);
      // You could also set an error message state here to display in the UI
    }
  };

  return (
    <div>
      {isCheckingConnection ? (
        <CircularProgress />
      ) : (
        <PropertiesTemplate
          properties={propertiesData?.results || []}
          onNextPage={() => setPage(page + 1)}
          onPreviousPage={() => setPage(page - 1)}
          hasNext={!!propertiesData?.next}
          hasPrevious={!!propertiesData?.previous}
          currentPage={page}
          showHidden={showHidden}
          onCheckboxChange={handleCheckboxChange}
          onVisibilityChange={() => fetchProperties()} // Re-fetch on visibility change
          isConnected={isConnected} // Pass connection state
          onButtonClick={handleButtonClick} // Pass button click handler
          apiKey={apiKey}
          setApiKey={handleApiKeyChange}
          onConnect={handleConnect}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          loading={loading} // Pass loading state to the button
          fetched={fetched} // Pass fetched state to the button
        />
      )}
    </div>
  );
};

export default Properties;
