import { apiSlice } from "../../../services/baseApi";
import { Property } from "./guest.types";

const propertiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExtendedPropertyById: builder.query<
      Property,
      { id: string; isReservation?: boolean; lang?: string }
    >({
      query: ({ id, isReservation, lang }) => {
        // Log the query params for debugging
        const params = {
          ...(isReservation && { reservation: true }), // Add reservation param if applicable
          ...(lang && { lang }), // Add lang param only if it's provided
        };

        console.log("API Query Params:", params); // Print the AI query parameters to the console

        return {
          url: `guest/${id}/`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

// Export hooks for use in components
export const { useGetExtendedPropertyByIdQuery } = propertiesApi;

export default propertiesApi;
