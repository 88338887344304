import React, { useState } from "react";
import { Category } from "../../../features/athenticated/dashboard/properties/properties.types";
import { FiMoreVertical } from "react-icons/fi";
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

type CategoryListProps = {
  categories: Category[];
  selectedCategory: string | null;
  setSelectedCategory: (key: string) => void;
  onAddCategory: (name: string, image: File) => void;
  onEditCategory: (id: string, name: string, image?: File | null) => void;
  onDeleteCategory: (id: string) => void;
  moveCategoryUp: (id: string) => void;
  moveCategoryDown: (id: string) => void;
};

const defaultImage = "path_to_default_image"; // Placeholder for default image

const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  moveCategoryUp,
  moveCategoryDown,
}) => {
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(
    null
  );
  const [editingCategoryName, setEditingCategoryName] = useState("");
  const [editingCategoryImage, setEditingCategoryImage] = useState<
    string | undefined
  >(undefined);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpenCategoryId, setMenuOpenCategoryId] = useState<string | null>(
    null
  );

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setEditingCategoryImage(imageUrl);
      setSelectedImageFile(file);
    }
  };

  const handleEditCategory = (id: string) => {
    if (editingCategoryName.trim()) {
      onEditCategory(id, editingCategoryName, selectedImageFile);
      setEditingCategoryId(null);
      setEditingCategoryImage(undefined);
      setSelectedImageFile(null);
    }
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenCategoryId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpenCategoryId(null);
  };

  const handleAddImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(file);
      setImagePreview(imageUrl);
    }
  };

  const handleAddCategory = async () => {
    if (newCategoryName && selectedImage) {
      await onAddCategory(newCategoryName, selectedImage);
      setNewCategoryName("");
      setSelectedImage(null);
      setImagePreview(null);
      setIsAddingCategory(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        padding: "16px",
        gap: "12px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            color: "#000",
            letterSpacing: 0.5,
          }}
        >
          Categories
        </Typography>
        <IconButton
          sx={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "50%",
            padding: "4px",
            "&:hover": { backgroundColor: "#333" },
            width: 32,
            height: 32,
          }}
          onClick={() => setIsAddingCategory(!isAddingCategory)}
        >
          <AddIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>

      {/* Add Category Form */}
      {isAddingCategory && (
        <Box
          sx={{
            backgroundColor: "#f7f7f8",
            borderRadius: 3,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "16px",
            mb: 2,
          }}
        >
          <Grid container spacing={2}>
            {/* Image Preview */}
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "8px",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  overflow: "hidden",
                  position: "relative",
                }}
                onClick={() =>
                  document.getElementById("category-image-upload")?.click()
                }
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Category Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <ImageIcon sx={{ color: "#999", fontSize: 40 }} />
                )}
              </Box>

              <input
                type="file"
                accept="image/*"
                onChange={handleAddImageChange}
                style={{ display: "none" }}
                id="category-image-upload"
              />
            </Grid>

            {/* Category Name and Buttons */}
            <Grid item xs={12} sm={8}>
              <TextField
                label="New Category Name"
                variant="outlined"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                fullWidth
                sx={{
                  input: {
                    color: "black",
                    backgroundColor: "#fff",
                    borderRadius: 2,
                    padding: "12px 16px",
                  },
                  label: { color: "#555" },
                }}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setIsAddingCategory(false)}
                  sx={{
                    mr: 1,
                    borderRadius: 2,
                    borderColor: "black",
                    color: "black",
                    padding: "6px 12px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: 2,
                    padding: "6px 12px",
                  }}
                  onClick={handleAddCategory}
                >
                  Add Category
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Category List */}
      {categories.map((category, index) => (
        <Box
          key={category.id}
          onClick={() => setSelectedCategory(category.id)}
          sx={{
            border:
              selectedCategory === category.id
                ? "2px solid black"
                : "1px solid #ccc",
            backgroundColor:
              selectedCategory === category.id ? "#f9f9f9" : "#fff",
            padding: "16px",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            transition: "background-color 0.3s ease, border 0.3s ease",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          {editingCategoryId === category.id ? (
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "8px",
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      overflow: "hidden",
                      position: "relative",
                    }}
                    onClick={() =>
                      document
                        .getElementById(`category-image-upload-${category.id}`)
                        ?.click()
                    }
                  >
                    {editingCategoryImage ? (
                      <img
                        src={editingCategoryImage}
                        alt="Category Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <ImageIcon sx={{ color: "#999", fontSize: 40 }} />
                    )}
                  </Box>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id={`category-image-upload-${category.id}`}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Edit Category Name"
                    variant="outlined"
                    value={editingCategoryName}
                    onChange={(e) => setEditingCategoryName(e.target.value)}
                    fullWidth
                    sx={{
                      input: {
                        color: "black",
                        backgroundColor: "#fff",
                        borderRadius: 2,
                        padding: "12px 16px",
                      },
                      label: { color: "#555" },
                    }}
                  />
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setEditingCategoryId(null)}
                      sx={{
                        mr: 1,
                        borderRadius: 2,
                        borderColor: "black",
                        color: "black",
                        padding: "6px 12px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: 2,
                        padding: "6px 12px",
                      }}
                      onClick={() => handleEditCategory(category.id)}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <IconButton
                  size="small"
                  disabled={index === 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    moveCategoryUp(category.id);
                  }}
                  sx={{ padding: "4px" }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={index === categories.length - 1}
                  onClick={(e) => {
                    e.stopPropagation();
                    moveCategoryDown(category.id);
                  }}
                  sx={{ padding: "4px" }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "8px",
                  backgroundColor: category.image ? "transparent" : "#e0e0e0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "12px",
                }}
              >
                {category.image ? (
                  <Box
                    component="img"
                    src={category.image}
                    alt={category.name}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <ImageIcon sx={{ fontSize: 30, color: "#9e9e9e" }} />
                )}
              </Box>

              <Typography
                variant="body1"
                sx={{ fontWeight: "500", color: "#333", flexGrow: 1 }}
              >
                {category.name}
              </Typography>

              {category.updated_by && (
                <IconButton
                  aria-label="more"
                  onClick={(e) => handleMenuOpen(e, category.id)}
                  size="small"
                  sx={{ color: "black" }}
                >
                  <FiMoreVertical size={18} />
                </IconButton>
              )}

              <Menu
                anchorEl={anchorEl}
                open={menuOpenCategoryId === category.id}
                onClose={handleMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    backgroundColor: "#fff",
                    color: "black",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEditingCategoryId(category.id);
                    setEditingCategoryName(category.name);
                    setEditingCategoryImage(category.image || undefined);
                    handleMenuClose();
                  }}
                  sx={{ color: "black" }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => onDeleteCategory(category.id)}
                  sx={{ color: "red" }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CategoryList;
