interface Media {
  file: string;
  id: string;
  order?: number;
  media_id?: string;
}

// FAQ Interface
export interface FAQ {
  id: string;
  question: string;
  answer: string;
}

export interface Guide {
  main_image: string | undefined;
  id: string;
  name: string;
  description: string;
  medias: Media[];
  property: string;
  category: string | null;
  is_important: boolean;
}

export interface Category {
  image: any;
  name: string;
  guides: Guide[];
}

interface Amenities {
  breakfast_included: boolean;
  has_parking: boolean;
  adults_only: boolean;
  pets_allowed: boolean | null;
  max_people: number;
  units: number;
  has_wifi: boolean;
  has_meal_plan: boolean;
  bedrooms: number;
  bathrooms: number;
  area_unit: string;
  area: number;
}

interface Contact {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface Property {
  language?: string;
  id: string;
  name: string;
  faqs: FAQ[]; // Added faqs field
  description: string;
  address: string;
  latitude: string;
  longitude: string;
  medias: Media[];
  main_image: string;
  is_hidden: boolean;
  amenities: Amenities;
  featured_guides: Guide[];
  categories: Category[];
  contact: Contact;
  city: string | null;
  state: string | null;
  zip: string | null;
  reservation?: Reservation | null; // Add reservation as optional and nullable
}

// Reservation interface (based on your earlier provided JSON)
export interface Reservation {
  id: string;
  arrival: string;
  departure: string;
  property: string;
  source: string;
  title: string;
  status: string;
  guest_link: string;
  number_of_guests: number;
  number_of_nights: number;
  language: string;
}

export interface StaticText {
  loading: string;
  error: string;
  languageSwitcherLabel: string;
  phoneLabel: string;
  notSpecified: string;
  emailLabel: string;
  guides: string;
  viewOnGoogleMaps: string;
  faqTitle: string;
  contactUs: string;
  noMediaImages: string;
  untitledGuide: string;
  noDescription: string;
  whatsapp: string;
  call: string;
  email: string;
  locationNotAvailable: string;
}

// Adjusted defaultStaticText values
export const defaultStaticText: StaticText = {
  loading: "Loading...",
  error: "Error loading property details",
  languageSwitcherLabel: "Select Language:",
  phoneLabel: "Phone",
  notSpecified: "Not specified",
  emailLabel: "Email",
  guides: "Guides",
  viewOnGoogleMaps: "View on Google Maps",
  faqTitle: "Frequently Asked Questions",
  contactUs: "Contact the Owner",
  noMediaImages: "No media images available for this guide.",
  untitledGuide: "Untitled Guide",
  noDescription: "No description available for this guide.",
  whatsapp: "WhatsApp",
  call: "Call",
  email: "Email",
  locationNotAvailable: "Location not available",
};
