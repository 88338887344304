const endpoints = {
  baseUrl: "https://api.portas.io/api/v1/",
  url: "https://portas-backend.s3-us-west-1.amazonaws.com",
  // baseUrl: "http://192.168.0.105:8000/api/v1/",
  login: "auth/login/",
  signup: "auth/register/",
  refresh: "auth/refresh/",
  googleLogIn: "auth/login/google/",
  forgetPass: "auth/forgot-password/",
  resendOtp: "auth/regen/",
  resetPass: "auth/reset-password/",
  verifyOtp: "/auth/verify/",
  properties: "properties/",
  guideCategories: "/categories/", // Endpoint for guide categories
  guide: "/guides/", // Base endpoint for guides
};
export default endpoints;
