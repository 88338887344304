import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist"; // Imports for state persistence
import { apiSlice } from "../services/baseApi"; // Import API slice, used for managing API data with RTK Query
import authReducer from "../redux/Slices/Authentication/authSlice"; // Import the authentication reducer
import { setupListeners } from "@reduxjs/toolkit/query"; // Setup listeners for auto-refetching
import storage from "redux-persist/es/storage"; // Specify storage method, typically localStorage
import appStateReducer from "../redux/Slices/appStateSlice"; // Import the app state slice
import guestReducer from "../redux/Slices/GuestPage/guestSlice"; // Import the property slice reducer
import propertyFaqReducer from "../redux/Slices/PropertyFaqs/propertyFaqSlice"; // Import the propertyFaq slice reducer

// Configure persistence settings for Redux Persist
const persistConfig = {
  key: "root", // Key used for local storage root item
  storage, // Use local storage as the default storage
  whitelist: ["auth", "sidebar", "appState"], // Specify slices to persist across sessions (auth and sidebar)
};

// Combine reducers into a root reducer
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer, // Add the RTK Query reducer for handling API data and caching
  auth: authReducer, // Add the authentication reducer to manage user login state and tokens
  appState: appStateReducer, // Add the app state slice to manage global states like loading, error, etc.
  property: guestReducer, // Add the property slice reducer for managing property-related state
  propertyFaq: propertyFaqReducer, // Add the propertyFaq slice reducer for managing property FAQ-related state
});

// Wrap the root reducer with persistence, applying the persistConfig
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
export const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer to enable state persistence
  middleware: (getDefaultMiddleware) =>
    // Add default middleware with customization
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these specific actions for non-serializable values, as persistence requires
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        ignoredPaths: ["register"], // Ignore specific paths in state for non-serializability
      },
    }).concat(apiSlice.middleware), // Add RTK Query middleware to handle API actions and caching
});

// Set up listeners for store actions, enabling features like auto-refetching
setupListeners(store.dispatch);

// Export persistor for use in wrapping the app, managing persistence lifecycle (e.g., rehydrating state)
export const persistor = persistStore(store);

// Define RootState and AppDispatch types for type-safe state and dispatch
export type RootState = ReturnType<typeof rootReducer>; // RootState type derived from the rootReducer's shape
export type AppDispatch = typeof store.dispatch; // AppDispatch type for dispatching actions
