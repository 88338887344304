import AWS from "aws-sdk";
import {
  defaultStaticText,
  StaticText,
} from "../../features/public/guestPage/guest.types";

// Initialize AWS Translate
const translate = new AWS.Translate({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION || "sa-east-1",
});

// Function to translate a single string using AWS Translate
const translateValue = async (text: string, targetLanguage: string) => {
  const params = {
    SourceLanguageCode: "en", // Assuming the default language is English
    TargetLanguageCode: targetLanguage,
    Text: text,
  };

  try {
    const result = await translate.translateText(params).promise();
    return result.TranslatedText;
  } catch (error) {
    console.error("Translation error:", error);
    return text; // Fallback to original text in case of error
  }
};

// Function to translate the entire StaticText interface
export const translateStaticText = async (
  targetLanguage: string
): Promise<StaticText> => {
  const translatedStaticText: StaticText = { ...defaultStaticText }; // Copy the default values to translate

  // Translate all top-level fields in StaticText
  translatedStaticText.loading = await translateValue(
    defaultStaticText.loading,
    targetLanguage
  );
  translatedStaticText.error = await translateValue(
    defaultStaticText.error,
    targetLanguage
  );
  translatedStaticText.languageSwitcherLabel = await translateValue(
    defaultStaticText.languageSwitcherLabel,
    targetLanguage
  );
  translatedStaticText.phoneLabel = await translateValue(
    defaultStaticText.phoneLabel,
    targetLanguage
  );
  translatedStaticText.notSpecified = await translateValue(
    defaultStaticText.notSpecified,
    targetLanguage
  );
  translatedStaticText.emailLabel = await translateValue(
    defaultStaticText.emailLabel,
    targetLanguage
  );
  translatedStaticText.guides = await translateValue(
    defaultStaticText.guides,
    targetLanguage
  );
  translatedStaticText.viewOnGoogleMaps = await translateValue(
    defaultStaticText.viewOnGoogleMaps,
    targetLanguage
  );
  translatedStaticText.faqTitle = await translateValue(
    defaultStaticText.faqTitle,
    targetLanguage
  );
  translatedStaticText.contactUs = await translateValue(
    defaultStaticText.contactUs,
    targetLanguage
  );
  translatedStaticText.noMediaImages = await translateValue(
    defaultStaticText.noMediaImages,
    targetLanguage
  );
  translatedStaticText.untitledGuide = await translateValue(
    defaultStaticText.untitledGuide,
    targetLanguage
  );
  translatedStaticText.noDescription = await translateValue(
    defaultStaticText.noDescription,
    targetLanguage
  );
  translatedStaticText.whatsapp = await translateValue(
    defaultStaticText.whatsapp,
    targetLanguage
  );
  translatedStaticText.call = await translateValue(
    defaultStaticText.call,
    targetLanguage
  );
  translatedStaticText.email = await translateValue(
    defaultStaticText.email,
    targetLanguage
  );
  translatedStaticText.locationNotAvailable = await translateValue(
    defaultStaticText.locationNotAvailable,
    targetLanguage
  );

  // Return the fully translated StaticText object
  return translatedStaticText;
};
